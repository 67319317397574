import React from "react";
import Component, {SimpleModel} from "../../models/Interfaces.tsx";
import {Errorcomponent} from "../"
import { ParseSimpleQueryResult, groupTwoColumnData, groupAndSumSimpleData, getLastKey } from "../../util/commonfunctions.tsx";
import { PresetIcons } from "../../util/constants.tsx";

import './smallcomponent.css'

export default function Smallcomponent(props: Component){ 
  const { title, explanation, queryResult} = props;

 
  let data: any[];
  try {
    const parsedData: any[] = JSON.parse(queryResult);
    data = groupTwoColumnData(parsedData);
  }
  catch (error) {
    return <Errorcomponent component={props} message="Error: Fant feil i dataene." />;
  }
  const lastKey = getLastKey(data[data.length - 1]); // Get the name of the last key
  const secondColumnValues = data.map(item => item[lastKey]);

  const difference =
    secondColumnValues.length > 1
      ? ((secondColumnValues[1] - secondColumnValues[0]) / secondColumnValues[0]) * 100
      : data.length === 1
      ? 0
      : null;
  const lastValue = secondColumnValues[secondColumnValues.length - 1];

  const percentageClass =
    difference !== null
      ? difference > 0
        ? 'positive'
        : difference < 0
        ? 'negative'
        : 'none'
      : 'none';

    const styleIcon =
    (
      percentageClass === 'positive' ? (
        PresetIcons['BsGraphUp']
      ) : percentageClass === 'negative' ? (
        PresetIcons['BsGraphDown']
      ) : (
        PresetIcons['PiChartLineDuotone']
      )
    );

const iconClassName = percentageClass === 'positive' ? 'positive' : percentageClass === 'negative' ? 'negative' : 'neutral';

//  const latestValue =
//     comparerModels.length > 0 ? comparerModels[comparerModels.length - 1].Value : 0;
// console.log(latestValue);

    
return(
    <div className="base-container" >
      <div className="title-container">
        {title}
      </div>
      <div className="small_component-container">
      <div className={`small_component-icon ${iconClassName}`}>
        {styleIcon }
        </div>
          <div className="small_component-info">
              <span className={`text-sm-percentage ${percentageClass}`}>
                {difference?.toFixed(1)}% 
              </span>
              <span className="bold-font">
                {new Intl.NumberFormat('no-NO', {
                  style: 'currency', 
                  currency:'NOK'
                  }).format(lastValue)}
              </span>
        </div>
      </div>
  </div>
)
}


// return(
//   <div className="base-container small_component-container" >
//     <div className="title-container">
//       {title}
//     </div>
//     <div className="small_component-container">
//     <div className={`small_component-icon ${iconClassName}`}>
//       {styleIcon }
//         {/* {extraIcon && PresetIcons[extraIcon]} */}
//       </div>
//         <div className="small_component-info">
//               <span className={`text-sm-percentage ${percentageClass}`}>
//                 {difference?.toFixed(1)}% 
//               </span>
//               <span className="bold-font">
//               {new Intl.NumberFormat('no-NO', {style: 'currency', currency:'NOK'}).format(latestValue)}
//               </span>
          
//         </div>
//       </div>
//   </div>
// )


// return(
//   <div className="base-container small_component-container" >
//     <div className="title-container small_component-title-container">
//       {title}
//     </div>
//     <div className="small_component-info-container">
//       <div className={`small_component-icon ${iconClassName}`}>
//         {styleIcon }
//         {/* {extraIcon && PresetIcons[extraIcon]} */}
//       </div>
//       <div className="small_component-info">
//             <span className={`text-sm-percentage ${percentageClass}`}>
//               {difference?.toFixed(1)}% 
//             </span>
//             <span className="bold-font">
//             {new Intl.NumberFormat('no-NO', {style: 'currency', currency:'NOK'}).format(latestValue)}
//             </span>
        
//       </div>
//     </div>
//   </div>
// )