import React, { useState, useEffect } from 'react';
import {AccordionItem, Loading, Messagebox} from './../index.js';
import { PresetIcons } from '../../util/constants.tsx';
import { getFAQ } from '../../services/api.tsx';
import { FAQDTO } from '../../models/Dtos.tsx';

import "./faq.css";

export default function FAQ(){
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [faqData, setFaqData] = useState<FAQDTO[] | null>([]);



  async function getFAQdata() {
    setIsLoading(true);
    try {
      const data = await getFAQ();
      console.log("faqData", data);
      setFaqData(data);
    } 
    catch (error)
    {
      setErrorMessage("Prøv igjen senere.");
      setIsError(true);
    }
    finally{
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getFAQdata();

  }, []);


  const handleItemClick = (index: number) => {
    setActiveItem(activeItem === index ? null : index);
  };

  // const faqData = [
  //   { question: 'Here is a ',
  //     answer: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.` },
  //   { question: 'Short Q2', answer: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.` },
  //   // Add more FAQ items as needed
  // ];

  return (
    <>
    <div className='title-container faq-title'>
      Ofte stilt spørsmål
    </div>
  
    {isLoading && <Loading />}
    {!isLoading && isError && (
      <Messagebox
        message={errorMessage}
        title="Ingen kontakt med APIet"
        icon={PresetIcons['FaTimesCircle']}
      />
    )}
    {!isLoading && !isError && (
      <>
        <span className="faq-subtitle">Trykk for å utvide</span>
        <div className="faq">
          {!faqData || faqData.length === 0 ? (
            <span>Ingen FAQ funnet</span>
          ) : (
            faqData.map((item, index) => (
              <AccordionItem
                key={index}
                question={item.question}
                answer={item.answer}
                isActive={activeItem === index}
                onClick={() => handleItemClick(index)}
              />
            ))
          )}
        </div>
      </>
    )}
  </>
    
  );
}