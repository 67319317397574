import React, {useEffect, useState} from 'react';
import {TableBody, TableHeader} from "./index.js";
import {TableProps, TotalFormatting} from '../../models/Interfaces.tsx';

import "./index.css";



function getDefaultSorting(defaultTableData, columns, sortField, sortOrder) {
  // Find the column to sort by based on the sortField parameter
  const sortColumn = columns.find(column => column.accessor === sortField);

  // If no column is found, return the original data unchanged
  if (!sortColumn) return defaultTableData;

  const { accessor } = sortColumn;

  const sorted = [...defaultTableData].sort((a, b) => {
    // Handle cases where the accessor is null
    if (a[accessor] === null) return 1;
    if (b[accessor] === null) return -1;
    if (a[accessor] === null && b[accessor] === null) return 0;

    // Determine sorting order based on sortOrder
    const ascending = a[accessor].toString().localeCompare(b[accessor].toString(), "en", {
      numeric: true,
    });

    return sortOrder === "asc" ? ascending : -ascending;
  });

  return sorted;
}

export const useSortableTable = (data, columns, defaultSortField, defaultSortOrder) => {
  const [tableData, setTableData] = useState(getDefaultSorting(data, columns, defaultSortField, defaultSortOrder));

  useEffect(() => {
    setTableData(getDefaultSorting(data, columns, defaultSortField, defaultSortOrder));
  }, [data, columns, defaultSortField, defaultSortOrder]);

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        // Handle null values
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
  
        // Parse the fields as numbers if they are numeric
        const valueA = Number(a[sortField]);
        const valueB = Number(b[sortField]);
  
        // Compare numerically if both values are valid numbers
        if (!isNaN(valueA) && !isNaN(valueB)) {
          return (valueA - valueB) * (sortOrder === "asc" ? 1 : -1);
        }
  
        // Fallback to string comparison if values are non-numeric
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
  
      setTableData(sorted);
    }
  };
  
  // const handleSorting = (sortField, sortOrder) => {
  //   if (sortField) {
  //     const sorted = [...tableData].sort((a, b) => {
  //       if (a[sortField] === null) return 1;
  //       if (b[sortField] === null) return -1;
  //       if (a[sortField] === null && b[sortField] === null) return 0;
  //       return (
  //         a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
  //           numeric: true,
  //         }) * (sortOrder === "asc" ? 1 : -1)
  //       );
  //     });
  //     setTableData(sorted);
  //   }
  // };

  return [tableData, handleSorting];
};

Table.defaultProps = {
  caption: 'title',
  defaultSortField: 'id',
  defaultSortOrder: 'desc',
}
export default function Table(props: TableProps){ //{ caption = 'title', data, columns, defaultSortField = 'id', defaultSortOrder = 'desc', showTotal = false }
  const { caption, data, columns, defaultSortField, defaultSortOrder, totalFormatting } = props;
  const [tableData, handleSorting] = useSortableTable(data, columns, defaultSortField, defaultSortOrder);
  return (
    <>
      <table className="table">
        <caption>{caption}</caption>
        <TableHeader {...{ columns, handleSorting }} />
        <TableBody columns={columns}tableData={tableData} totalFormatting={totalFormatting}  />
      </table>
    </>
  );
};





// export const useSortableTable = (data, columns, defaultSortField, defaultSortOrder) => {
//   const [tableData, setTableData] = useState(getDefaultSorting(data, columns, defaultSortField, defaultSortOrder));

//   const handleSorting = (sortField, sortOrder) => {
//     if (sortField) {
//       const sorted = [...tableData].sort((a, b) => {
//         if (a[sortField] === null) return 1;
//         if (b[sortField] === null) return -1;
//         if (a[sortField] === null && b[sortField] === null) return 0;
//         return (
//           a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
//             numeric: true,
//           }) * (sortOrder === "asc" ? 1 : -1)
//         );
//       });
//       setTableData(sorted);
//     }
//   };

//   return [tableData, handleSorting];
// };




//export const useSortableTable = (data, columns) => {

  //const [tableData, setTableData] = useState(getDefaultSorting(data, columns));

//   const handleSorting = (sortField, sortOrder) => {
//     if (sortField) {
//       const sorted = [...tableData].sort((a, b) => {
//         if (a[sortField] === null) return 1;
//         if (b[sortField] === null) return -1;
//         if (a[sortField] === null && b[sortField] === null) return 0;
//         return (
//           a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
//             numeric: true,
//           }) * (sortOrder === "asc" ? 1 : -1)
//         );
//       });
//       setTableData(sorted);
//     }
//   };

//   return [tableData, handleSorting];
// };
// function getDefaultSorting(defaultTableData, columns) {
//   const sorted = [...defaultTableData].sort((a, b) => {
//       const filterColumn = columns.filter((column) => column.sortbyOrder);
//       const defaultAccessor = columns.length > 0 ? columns[0].accessor : "id";
//       let { accessor = defaultAccessor, sortbyOrder = "asc" } = Object.assign(
//           {},
//           ...filterColumn
//       );

//       if (a[accessor] === null) return 1;
//       if (b[accessor] === null) return -1;
//       if (a[accessor] === null && b[accessor] === null) return 0;
//       // const atest = a[accessor];
//       // const btest = b[accessor];
//       // debugger;
//       const ascending = a[accessor]
//           .toString()
//           .localeCompare(b[accessor].toString(), "en", {
//               numeric: true,
//           });

//       return sortbyOrder === "asc" ? ascending : -ascending;
//   });
//   return sorted;
// }






//  const Table = ({ caption, data, columns }) => {

//   const [tableData, handleSorting] = useSortableTable(data );

//   return (
//     <>
      
//       <table className="table">
//         <caption>{caption}</caption>
//         <TableHeader {...{ columns, handleSorting }} />
//         <TableBody {...{ columns, tableData }} />
//       </table>
//     </>
//   );
// };

// export default Table;

// function getDefaultSorting(defaultTableData, columns) {
//   const sorted = [...defaultTableData].sort((a, b) => {
//     const filterColumn = columns.filter((column) => column.sortbyOrder);

//     // Merge all array objects into single object and extract accessor and sortbyOrder keys
//     let { accessor = "id", sortbyOrder = "asc" } = Object.assign(
//       {},
//       ...filterColumn
//     );

//     if (a[accessor] === null) return 1;
//     if (b[accessor] === null) return -1;
//     if (a[accessor] === null && b[accessor] === null) return 0;

//     const ascending = a[accessor]
//       .toString()
//       .localeCompare(b[accessor].toString(), "en", {
//         numeric: true,
//       });

//     return sortbyOrder === "asc" ? ascending : -ascending;
//   });
//   return sorted;
// }
